<template>
  <div
    class="h-dvh bg-[linear-gradient(135deg,#00364D_10.3%,#000_51.81%,#00364D_83.74%)]"
  >
    <div
      class="flex min-h-full flex-1 flex-col justify-between px-6 py-12 lg:px-8"
    >
      <div class="flex min-h-full flex-1 flex-col justify-center">
        <div class="sm:mx-auto sm:w-full sm:max-w-sm">
          <router-link to="/">
            <img
              class="h-[7.4rem] mx-auto"
              src="../../assets/logo.jpg"
              alt="HÜBNER Group – Logo"
            />
          </router-link>
          <h2
            class="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-white"
          >
            Sign in to access the website
          </h2>
        </div>

        <div class="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <form class="space-y-6" @submit.prevent="handleLogin">
            <div>
              <div class="flex items-center justify-between">
                <label
                  for="password"
                  class="block text-base font-medium leading-6 text-white"
                  >Password</label
                >
              </div>
              <div class="mt-2">
                <input
                  v-model="password"
                  id="password"
                  name="password"
                  type="password"
                  autocomplete="current-password"
                  required
                  class="block w-full border-0 bg-white/5 p-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-primary-500 text-base leading-6"
                />
              </div>
            </div>

            <div>
              <button
                type="submit"
                class="flex w-full justify-center bg-primary-500 px-3 py-2 text-base font-semibold leading-6 text-white shadow-sm hover:bg-primary-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-500"
              >
                Sign in
              </button>
            </div>
          </form>
        </div>
      </div>
      <div>
        <p
          class="text-white text-sm mt-10 text-left mx-auto max-w-[1440px] leading-loose"
        >
          <span class="font-bold border-b border-white pb-0.5 w-fit"
            >This digital user interface is intended exclusively for sales
            purposes and is not to be shared with third parties.</span
          >
          As it is not responsive, please open it in full-screen mode to ensure
          all content is displayed correctly.<br />
          For any questions or additional support, please contact the Marketing
          Team directly at
          <a
            href="mailto:marketing@hubner-group.com"
            class="text-primary-500 hover:border-b hover:border-primary-500 pb-0.5 w-fit"
            >marketing@hubner-group.com</a
          >.<br />Copyright &copy; HÜBNER GmbH & Co. KG
        </p>
        <p
          class="text-white text-sm mt-10 text-left mx-auto max-w-[1440px] leading-loose"
        >
          <span class="font-bold border-b border-white pb-0.5 w-fit"
            >Diese digitale Benutzeroberfläche ist ausschließlich für
            Vertriebszwecke bestimmt und darf nicht an Dritte weitergegeben
            werden.</span
          >
          Da sie nicht responsiv ist, öffnen Sie sie bitte im Vollbildmodus, um
          sicherzustellen, dass alle Inhalte korrekt angezeigt werden.<br />
          Bei Fragen oder wenn Sie weitere Unterstützung benötigen, wenden Sie
          sich bitte direkt an das Marketing-Team unter
          <a
            href="mailto:marketing@hubner-group.com"
            class="text-primary-500 hover:border-b hover:border-primary-500 pb-0.5 w-fit"
            >marketing@hubner-group.com</a
          >.<br />Copyright &copy; HÜBNER GmbH & Co. KG
        </p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { useRouter } from "vue-router";

const password = ref("");
const router = useRouter();

function handleLogin() {
  if (password.value === "InnoTrans-2024") {
    localStorage.setItem("isAuthenticated", "true");
    router.push("/");
  } else {
    alert("Incorrect password");
  }
}
</script>

<style scoped>
.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
</style>
